import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '../store'
import nprogress from 'nprogress'
import { fetchUnreadNotificationCount } from '../composables/use-unread-notification-count'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
	{
		path: '/',
		name: 'Login',
		component: () => import('../Base/login/views/TheLogin'),
		meta: {
			screenName: 'Login'
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../Base/register/views/TheRegister'),
		meta: {
			auth: false,
			screenName: 'Sign Up'
		}
	},
	{
		// TODO: change it to forgot password for same name w/ mobile app
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import('../Base/setPassword/views/ResetPassword'),
		meta: {
			auth: false,
			screenName: 'Forgot Password'
		}
	},
	{
		path: '/create-password',
		name: 'CreatePassword',
		component: () => import('../Base/setPassword/views/CreatePassword'),
		meta: {
			auth: false,
			screenName: 'Create New Password'
		}
	},
	{
		path: '/change-password',
		name: 'ChangePassword',
		component: () => import('../modules/changePassword/views/ChangePassword'),
		meta: {
			auth: true,
			screenName: 'Change Password'
		}
	},
	{
		path: '*',
		name: 'The404Page',
		component: () => import('../components/The404Page')
		// meta: {
		//   auth: false
		// }
	},
	{
		path: '/feedback',
		name: 'Feedback',
		component: () => import('../modules/feedback/views/Feedback'),
		meta: {
			auth: true,
			screenName: 'User Feedback'
		}
	},
	{
		path: '/ReportProblem',
		name: 'ReportProblem',
		component: () => import('../modules/reportProblem/views/reportProblem'),
		meta: {
			auth: true,
			screenName: 'ReportAProblem'
		}
	},
	{
		path: '/verify-otp',
		name: 'VerifyOtp',
		component: () => import('../Base/setPassword/views/VerifyOtp'),
		meta: {
			auth: false,
			screenName: 'Forgot Otp Verify'
		}
	},
	{
		path: '/verify-phone',
		name: 'VerifyPhoneNumber',
		component: () => import('../Base/register/views/VerifyPhoneNumber'),
		meta: {
			//auth: false,
			screenName: 'Verify Contact Number'
		}
	},
	{
		path: '/verify-phone-otp',
		name: 'VerifyOtpForPhone',
		component: () => import('../Base/register/views/VerifyOtpForPhone'),
		meta: {
			auth: false,
			screenName: 'Verify Contact Number Otp'
		}
	},
	{
		path: '/register-verify-otp',
		name: 'RegisterVerifyOtp',
		component: () => import('../Base/register/views/RegisterVerifyOtp'),
		meta: {
			auth: false,
			screenName: 'Sign Up Verify OTP'
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../modules/dashboard/views/TheDashboard'),
		meta: {
			auth: true,
			screenName: 'Dashboard'
		}
	},
	{
		path: '/dashboard/new',
		name: 'Dashboard',
		component: () => import('../modules/dashboard/views/DashboardNew'),
		meta: {
			auth: true,
			screenName: 'Dashboard'
		}
	},

	{
		path: '/follow-up',
		name: 'FollowUp',
		component: () => import('../modules/followup/views/FollowUpList'),
		meta: {
			auth: true,
			screenName: 'Follow Up'
		}
	},
	{
		path: '/patient',
		name: 'Patient',
		component: () => import('../modules/patient/views/PatientList'),
		meta: {
			auth: true,
			screenName: 'Patient Listing'
		}
	},
	{
		path: '/patient-add/',
		name: 'PatientAdd',
		component: () => import('../modules/patient/views/PatientAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Patient'
		}
	},
	{
		path: '/patient-add/:id',
		name: 'PatientAddUpdate',
		component: () => import('../modules/patient/views/PatientAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Patient'
		}
	},
	{
		path: '/patient-detail/:id',
		name: 'ViewDetail',
		component: () => import('../modules/patient/views/PatientDetail'),
		meta: {
			auth: true,
			screenName: 'Patient Detail'
		}
	},
	{
		path: '/patient/edit/:id',
		name: 'Patient Edit',
		component: () => import('../modules/patient/views/PatientDetail'),
		meta: {
			auth: true,
			screenName: 'Patient Detail'
		}
	},
	{
		path: '/patient-detail',
		name: 'Patient Detail',
		component: () => import('../modules/patient/views/PatientDetail'),
		meta: {
			auth: true,
			screenName: 'Patient Detail'
		}
	},
	{
		path: '/treatment',
		name: 'Treatment',
		component: () => import('../modules/treatment/views/TreatmentList'),
		meta: {
			auth: true,
			screenName: 'Treatment Listing'
		}
	},
	{
		path: '/treatment-add',
		name: 'Addtreatment',
		component: () => import('../modules/treatment/views/TreatmentAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Treatment'
		}
	},
	{
		path: '/treatment-add/:id',
		name: 'PatientAddtreatment',
		component: () => import('../modules/treatment/views/TreatmentAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Treatment'
		}
	},
	{
		path: '/treatment/add/:type=:id',
		name: 'AddPatienttreatment',
		component: () => import('../modules/treatment/views/TreatmentAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Treatment'
		}
	},
	{
		path: '/treatment/edit/:type=:id',
		name: 'Edittreatment',
		component: () => import('../modules/treatment/views/TreatmentAdd'),
		meta: {
			auth: true,
			screenName: 'Add/Edit Treatment'
		}
	},
	{
		path: '/medicine',
		name: 'Medicine',
		component: () => import('../modules/medicine/views/MedicineList'),
		meta: {
			auth: true,
			screenName: 'Medicine Listing'
		}
	},
	{
		path: '/reports',
		name: 'Report',
		component: () => import('../modules/reports/views/Report'),
		meta: {
			auth: true,
			screenName: 'Report'
		}
	},
	{
		path: '/profile',
		component: () => import('../modules/profile/layouts/TheProfile.vue'),
		meta: {
			auth: true,
			screenName: 'Edit Profile'
		},
		children: [
			{
				path: '',
				component: () => import('../modules/profile/views/ProfilePersonalDetails.vue'),
				name: 'ProfilePersonal',
				meta: {
					auth: true,
					screenName: 'Edit Profile'
				}
			},
			{
				path: 'education',
				component: () => import('../modules/profile/views/ProfileEducationDetails.vue'),
				name: 'ProfileEducation',
				meta: {
					auth: true,
					screenName: 'Profile Education'
				}
			},
			{
				path: 'certificate',
				component: () => import('../modules/profile/views/ProfileCertificateDetails.vue'),
				name: 'ProfileCertificate',
				meta: {
					auth: true,
					screenName: 'Profile Certificate'
				}
			}
		]
	},
	{
		path: '/timeslot',
		name: 'Timeslot',
		component: () => import('../modules/timeslot/views/TheTimeslot'),
		meta: {
			auth: true,
			screenName: 'Hospital Timing'
		}
	},
	{
		path: '/appointments',
		name: 'Appointments',
		component: () => import('@/modules/appointments/views/Appointments.vue'),
		meta: {
			auth: true,
			screenName: 'Appointment Listing'
		}
	},
	{
		path: '/holidays',
		name: 'Holidays',
		component: () => import('@/modules/holidays/views/Holidays.vue'),
		meta: {
			auth: true,
			screenName: 'Holiday Listing'
		}
	},
	{
		path: '/manage-notifications',
		name: 'ManageNotifications',
		component: () => import('@/modules/notifications/views/ManageNotifications.vue'),
		meta: {
			auth: true,
			screenName: 'Manage Notifications'
		}
	}
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

router.beforeEach((to, _, next) => {
	nprogress.start()
	store.commit('setScreenName', to.meta.screenName ?? to.name ?? to.path)
	const isLoggedIn =
		localStorage.getItem('isLogin') == true || localStorage.getItem('isLogin') == 'true'

	if (isLoggedIn) {
		// can't go to not logged in routes if logged in
		if (!to.meta.auth) {
			// like login, register, verify otp etc..
			if (to.meta.screenName != 'Verify Contact Number Otp') {
				if (to.query.enc) {
					localStorage.clear()
					next({ path: `/?enc=${to.query.enc}` })
					return
				} else {
					next({ name: 'Dashboard' })
					return
				}
			}
			next()
			return
		}
	}

	if (to && to.meta && to.meta.auth) {
		if (!isLoggedIn) {
			next({ name: 'Login' })
			return
		}
	}

	next()
})

router.afterEach((to) => {
	nprogress.done()
	const protectedRoute = to.meta?.auth

	if (protectedRoute) fetchUnreadNotificationCount()
})

export default router
